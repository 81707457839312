
import Btn from "@/components/Btn.vue";
import Card from "@/components/Card.vue";
import LessonItem from "@/components/LessonItem.vue";
import { isSameOrAfterToday } from "@/plugins/moment";
import * as Api from "@/types/graphql";
import {
  CourseAvailability,
  CourseStatus,
  userIsSubscribed,
} from "@/utils/courses";
import * as _ from "lodash";
import { Component, Vue } from "vue-property-decorator";
import { Action, Mutation, State } from "vuex-class";
import { State as StateStore } from "../store";

@Component({
  components: { Card, Btn, LessonItem },
})
export default class NextLessonsView extends Vue {
  @State((state: StateStore) => state.auth.user) user: Api.User;
  @Mutation setFillHeightOnMainContainer;
  @Action getCoursesByStudent;
  @Mutation showSuccess;

  courses = null;
  lessons = [];

  async mounted() {
    this.setFillHeightOnMainContainer(false);
    this.courses = await this.getCoursesByStudent({
      userGuid: this.user.guid,
      availability: [CourseAvailability.Public, CourseAvailability.Private],
      status: [CourseStatus.Published],
    });
    _.forEach(this.courses, (course) => {
      if (userIsSubscribed(course, this.user.guid)) {
        _.forEach(course.lessons, (lesson) => {
          lesson["courseName"] = course.course_title;
          this.lessons.push(lesson);
        });
      }
    });
    this.lessons = _.sortBy(this.lessons, ["lesson_date", "lesson_from"]);
    this.lessons = _.filter(this.lessons, (l) => {
      return isSameOrAfterToday(l.lesson_date);
    });
  }
}
